import { render, staticRenderFns } from "./categoryConfig.vue?vue&type=template&id=627d6fe2&scoped=true&"
import script from "./categoryConfig.vue?vue&type=script&lang=js&"
export * from "./categoryConfig.vue?vue&type=script&lang=js&"
import style0 from "./categoryConfig.vue?vue&type=style&index=0&id=627d6fe2&scoped=true&lang=css&"
import style1 from "./categoryConfig.vue?vue&type=style&index=1&id=627d6fe2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627d6fe2",
  null
  
)

export default component.exports